export const healthcareSectorAreas = [
  { name: 'Area personale di supporto', id: 'supporting' },
  { name: 'Area operatori', id: 'operators' },
  { name: 'Area assistenti', id: 'assistants' },
  { name: 'Area professionisti della salute e funzionari', id: 'professionals' },
];

export const getHealthcareSectorArea = code => {
  const healthcareSectorArea = healthcareSectorAreas.find(hsa => hsa.id === code);
  if (!healthcareSectorArea) {
    return null;
  }

  return healthcareSectorArea;
};

export const formatHealthcareSectorArea = code => {
  const healthcareSectorArea = getHealthcareSectorArea(code);
  if (healthcareSectorArea === null) {
    return '';
  }

  return healthcareSectorArea.name;
};
