import Router from 'vue-router';

const routerGenerator = (config = {}, beforeEach = []) => {
  const options = {
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
      // https://router.vuejs.org/guide/advanced/scroll-behavior.html
      if (to.hash) {
        const header = document.querySelector('.main-header');
        return window.scrollTo({ top: document.querySelector(to.hash).offsetTop + (header?.scrollHeight || 0), behavior: 'smooth' });
      }

      if (savedPosition) {
        return window.scrollTo({ top: savedPosition.y, behavior: 'smooth' });
      }

      return window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    ...config,
  };

  const router = new Router(options);

  if (beforeEach.length) {
    for (const el of beforeEach) {
      router.beforeEach(el);
    }
  }

  return router;
};

export default routerGenerator;
