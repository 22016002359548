import Vue from 'vue';
import Vuex from 'vuex';
import createMultiTabState from 'vuex-multi-tab-state';

import survey from './modules/survey.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    survey,
  },
  strict: false,
  plugins: [
    createMultiTabState(),
  ],
});
