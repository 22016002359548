export const sheFiguresAreas = [
  { id: 'natural_science', name: 'Natural science' },
  { id: 'engineering_technology', name: 'Engineering and technology' },
  { id: 'medical', name: 'Medical and Health Sciences' },
  { id: 'agricultural_sciences', name: 'Agricultural sciences' },
  { id: 'social_sciences', name: 'Social sciences' },
  { id: 'humanities', name: 'Humanities and the arts' },
];

export const getSheFiguresArea = code => {
  const sheFiguresArea = sheFiguresAreas.find(sf => sf.id === code);
  if (!sheFiguresArea) {
    return null;
  }

  return sheFiguresArea;
};

export const formatSheFiguresArea = code => {
  const sheFiguresArea = getSheFiguresArea(code);
  if (sheFiguresArea === null) {
    return '';
  }

  return sheFiguresArea.name;
};
