import { mapGetters } from 'vuex';

// University
import { universities } from '@/helpers/university/universities.js';
import { sheFiguresAreas } from '@/helpers/university/sheFiguresAreas.js';
import { cunAreas } from '@/helpers/university/cunAreas.js';
import { iscedF2013Fields } from '@/helpers/university/iscedF2013Fields.js';

// Healthcare
import { healthcareSectorAreas } from '@/helpers/healthcare/healthcareSectorAreas.js';
import { healthcareSectorTasks } from '@/helpers/healthcare/healthcareSectorTasks.js';
import { healthcareManagementAreas } from '@/helpers/healthcare/healthcareManagementAreas.js';
import { healthcareManagementProfiles } from '@/helpers/healthcare/healthcareManagementProfiles.js';

import SurveyService from '@/services/SurveyService.js';

import termMixin from '@/mixins/termMixin.js';
import lawMixin from '@/mixins/lawMixin.js';

export default {
  mixins: [termMixin, lawMixin],
  computed: {
    ...mapGetters('survey', {
      isFirstStepCompleted: 'isFirstStepCompleted',
      totalWorkers: 'totalWorkers',
      isNotUniversita: 'isNotUniversita',
      isUniversita: 'isUniversita',
      isAziendaSanitaria: 'isAziendaSanitaria',
      isAziendaSanitariaPubblica: 'isAziendaSanitariaPubblica',
      isAziendaSanitariaPrivata: 'isAziendaSanitariaPrivata',
      isAziendaPrivata: 'isAziendaPrivata',
      isAziendaPubblica: 'isAziendaPubblica',
      isAmministrazioneStatale: 'isAmministrazioneStatale',
      isAziendaPartecipata: 'isAziendaPartecipata',
      referenceYear: 'referenceYear',
      safetyReferenceYear: 'safetyReferenceYear',
    }),
    surveyData: {
      get () {
        return this.$store.state.survey.data;
      },
    },
    universities () {
      return universities;
    },
    sheFiguresAreas () {
      return sheFiguresAreas;
    },
    iscedF2013Fields () {
      return iscedF2013Fields;
    },
    cunAreas () {
      return cunAreas;
    },
    healthcareSectorAreas () {
      return healthcareSectorAreas;
    },
    healthcareSectorTasks () {
      return healthcareSectorTasks;
    },
    healthcareManagementAreas () {
      return healthcareManagementAreas;
    },
    healthcareManagementProfiles () {
      return healthcareManagementProfiles;
    },
  },
  methods: {
    setRadio (path, event) {
      if (!event.target.checked) {
        return;
      }

      this.updateSurveyData(path, parseInt(event.target.value));
    },
    setCheckbox (path, event) {
      if (!event.target.checked) {
        this.updateSurveyData(path, 0);
      } else {
        this.updateSurveyData(path, 1);
      }
    },
    setTextAsInteger (path, event) {
      this.updateSurveyData(path, parseInt(event.target.value));
    },
    setTextAsFloat (path, event) {
      this.updateSurveyData(path, parseFloat(event.target.value));
    },
    setText (path, event) {
      this.updateSurveyData(path, event.target.value);
    },
    updateSurveyData (path, value) {
      const payload = {};
      payload[path] = value;

      this.$store.commit('survey/updateData', payload);
    },
    batchUpdateSurveyData (obj) {
      this.$store.commit('survey/updateData', obj);
    },

    sendSurvey () {
      return this.$api.sendSurvey({
        file: SurveyService.prepareFileContent(
          this.$store.state.survey.headers,
          this.$store.state.survey.preferences,
          this.$store.state.survey.data,
        ),
      })
        .then(res => {
          this.$store.commit('survey/setResult', res.data);

          return res;
        })
        .then(() => this.$router.push({ name: 'result' }))
      ;
    },
  },
};
