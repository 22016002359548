<template>
  <component :is="layout" />
</template>

<script>

import LayoutPublic from '@/views/layouts/LayoutPublic.vue';
import LayoutSurvey from '@/views/layouts/LayoutSurvey.vue';

export default {
  computed: {
    layoutName () {
      return this.$route?.meta?.layout || 'survey';
    },
    layout () {
      if (this.layoutName === 'public') {
        return LayoutPublic;
      }

      return LayoutSurvey;
    },
  },
};

</script>

<style lang="scss">

#app {
  min-height: 100%;
  height: 100%;
}

</style>
