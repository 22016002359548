export const healthcareManagementProfiles = [
  { name: 'Dirigenza medica', id: 'medical' },
  { name: 'Dirigenza sanitaria', id: 'healthcare' },
  { name: 'Dirigenza delle professioni sanitarie', id: 'health-professions' },
  { name: 'Dirigenza professionale, tecnica, amministrativa', id: 'pta' },
];

export const getHealthcareManagementProfile = code => {
  const healthcareManagementProfile = healthcareManagementProfiles.find(hsa => hsa.id === code);
  if (!healthcareManagementProfile) {
    return null;
  }

  return healthcareManagementProfile;
};

export const formatHealthcareManagementProfile = code => {
  const healthcareManagementProfile = getHealthcareManagementProfile(code);
  if (healthcareManagementProfile === null) {
    return '';
  }

  return healthcareManagementProfile.name;
};
