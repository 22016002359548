export const healthcareManagementAreas = [
  { name: 'Area medica', id: 'medical' },
  { name: 'Area chirurgica', id: 'surgical' },
  { name: 'Area dei servizi', id: 'service' },
  { name: 'Altre aree', id: 'others' },
];

export const getHealthcareManagementArea = code => {
  const healthcareManagementArea = healthcareManagementAreas.find(hsa => hsa.id === code);
  if (!healthcareManagementArea) {
    return null;
  }

  return healthcareManagementArea;
};

export const formatHealthcareManagementArea = code => {
  const healthcareManagementArea = getHealthcareManagementArea(code);
  if (healthcareManagementArea === null) {
    return '';
  }

  return healthcareManagementArea.name;
};
