export const healthcareSectorTasks = [
  { name: 'Ruolo sanitario', id: 'sanitary' },
  { name: 'Ruolo amministrativo', id: 'administrative' },
  { name: 'Ruolo tecnico', id: 'technical' },
  { name: 'Ruolo socio-sanitario', id: 'socio-health' },
];

export const getHealthcareSectorTask = code => {
  const healthcareSectorTask = healthcareSectorTasks.find(hsa => hsa.id === code);
  if (!healthcareSectorTask) {
    return null;
  }

  return healthcareSectorTask;
};

export const formatHealthcareSectorTask = code => {
  const healthcareSectorTask = getHealthcareSectorTask(code);
  if (healthcareSectorTask === null) {
    return '';
  }

  return healthcareSectorTask.name;
};
