import logoutRoute from '@/libs/OAuth2/routes/logoutRoute.js';
import catchallToRootRoute from '@/libs/Router/routes/catchallToRootRoute.js';

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      layout: 'public',
      keepResult: true,
    },
  },
  {
    path: '/step-1',
    name: 'step1',
    component: () => import('@/views/pages/Step1.vue'),
  },
  {
    path: '/step-2',
    name: 'step2',
    component: () => import('@/views/pages/Step2.vue'),
  },
  {
    path: '/step-3',
    name: 'step3',
    component: () => import('@/views/pages/Step3.vue'),
  },
  {
    path: '/step-4',
    name: 'step4',
    component: () => import('@/views/pages/Step4.vue'),
  },
  {
    path: '/step-5',
    name: 'step5',
    component: () => import('@/views/pages/Step5.vue'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/pages/Result.vue'),
    meta: {
      keepResult: true,
    },
  },
  {
    path: '/glossary',
    name: 'glossary',
    component: () => import('@/views/pages/Glossary.vue'),
    meta: {
      keepResult: true,
    },
  },
  {
    path: '/laws',
    name: 'laws',
    component: () => import('@/views/pages/LawReference.vue'),
    meta: {
      keepResult: true,
    },
  },
  {
    path: '/credits',
    name: 'credits',
    component: () => import('@/views/pages/Credits.vue'),
    meta: {
      keepResult: true,
    },
  },

  logoutRoute,
  catchallToRootRoute,
];
