<template>
  <div id="app">
    <VaridigeHeader hide-menu-toggle />
    <div class="container-fluid main-page">
      <div class="main-content-container">
        <router-view class="main-content" />
        <VaridigeFooter />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    VaridigeHeader: () => import('@/views/components/Header'),
    VaridigeFooter: () => import('@/views/components/Footer'),
  },
};

</script>
