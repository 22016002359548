import $axios from '../libs/OAuth2/services/axios';

export default {
  sendSurvey: survey => $axios.post('/survey', survey),
  getBilancioDiGenere: survey => $axios.post('/survey-bilancio-di-genere', survey, {
    responseType: 'blob',
  }),
  getUniData: (uni, year) => $axios.get(`/uni/data?uni=${uni}&year=${year}`),
  getDocentiTotUniData: (uni, year) => $axios.get(`/uni/data/docentiTot?uni=${uni}&year=${year}`),
  getDocentiRoleUniData: (uni, year, role) => $axios.get(`/uni/data/docentiRole?uni=${uni}&year=${year}&role=${role}`),
  getDocentiRoleCunUniData: (uni, year, role, cun) => $axios.get(`/uni/data/docentiRoleCun?uni=${uni}&year=${year}&role=${role}&cun=${cun}`),
  getDocentiRoleMeanAgeUniData: (uni, year, role) => $axios.get(`/uni/data/docentiRoleMeanAge?uni=${uni}&year=${year}&role=${role}`),
  getStudentiTotUniData: (uni, year) => $axios.get(`/uni/data/studentiTot?uni=${uni}&year=${year}`),
  getStudentiIscrittiIscedUniData: (uni, year, isced, type) => $axios.get(`/uni/data/studentiIscrittiIsced?uni=${uni}&year=${year}&isced=${isced}&type=${type}`),
  getStudentiIscrittiProvenienzaUniData: (uni, year, provenienza, type) => $axios.get(`/uni/data/studentiIscrittiProvenienza?uni=${uni}&year=${year}&provenienza=${provenienza}&type=${type}`),
  getPtaTotUniData: (uni, year) => $axios.get(`/uni/data/ptaTot?uni=${uni}&year=${year}`),
};
