/* eslint-disable import/first */
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

import Config from './libs/Config/plugins/Config';
Vue.use(Config);

import ApiVue from './libs/Api/plugins/Api';
import endpoints from './config/endpoints';
Vue.use(ApiVue, {
  endpoints,
});
Vue.use(BootstrapVue);

import Sweetalert2Vue from 'vue-sweetalert2';
Vue.use(Sweetalert2Vue);

import OAuth2Vue from './libs/OAuth2/plugins/OAuth2';
Vue.use(OAuth2Vue, {
  config: {
    client_id: Config._getService().get('client_id'),
    client_secret: Config._getService().get('client_secret'),
  },
});

import Router from 'vue-router';
Vue.use(Router);

import VTooltipPlugin from 'v-tooltip';
Vue.use(VTooltipPlugin, {
  defaultHtml: false,
});

import store from './store/index.js';

/**
 * APP INITIALIZATION
 */
import App from './views/App.vue';
import routerGenerator from './libs/Router/services/router';
import routes from './config/routes';

Vue.config.productionTip = false;

new Vue({
  ...App,
  router: routerGenerator({ routes }, [
    (to, from, next) => {
      if (!to?.meta?.keepResult) {
        store.commit('survey/resetResult');
      }

      next();
    },
  ]),
  store,
}).$mount('#app');

import './assets/scss/bootstrap.scss';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/scss/style.scss';
(() => import(/* webpackChunkName: "css-fontawesome" */ './assets/scss/fontawesome.scss'))();
(() => import(/* webpackChunkName: "css-print" */ './assets/scss/print.scss'))();
