export const iscedF2013Fields = [
  { id: 'istruzione', name: 'Istruzione' },
  { id: 'umanistico', name: 'Discipline umanistiche ed artistiche' },
  { id: 'scienze_sociali', name: 'Scienze sociali, giornalismo e informazione' },
  { id: 'attivita_imprenditoriali', name: 'Attività imprenditoriali, commerciali e diritto' },
  { id: 'scienze_naturali', name: 'Scienze naturali, matematica e statistica' },
  { id: 'ict', name: 'Tecnologie dell\'informazione e della comunicazione (ICT)' },
  { id: 'ingegneria', name: 'Ingegneria, attività manifatturiere e costruzioni' },
  { id: 'agricoltura', name: 'Agricoltura, silvicultura, pesca e veterinaria' },
  { id: 'sanita', name: 'Sanità e assistenza sociale' },
  { id: 'servizi', name: 'Servizi' },
];

export const getIsced2013Field = code => {
  const iscedF2013Field = iscedF2013Fields.find(sf => sf.id === code);
  if (!iscedF2013Field) {
    return null;
  }

  return iscedF2013Field;
};

export const formatIsced2013Field = code => {
  const iscedF2013Field = getIsced2013Field(code);
  if (iscedF2013Field === null) {
    return '';
  }

  return iscedF2013Field.name;
};
