import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';
import glossary from '@/data/glossary.json';

export default {
  mixins: [modalsManagerMixin],
  data () {
    return {
      term: null,
      termText: null,
    };
  },
  methods: {
    showTerm (termId) {
      const term = (glossary?.data || []).find(g => g.id === termId);
      if (!term) {
        return;
      }

      this.term = term.term;
      this.termText = term.html;
      this.$openModal('terms');
    },
    closeTerm () {
      this.term = null;
      this.termText = null;
      this.$closeModal('terms');
    },
  },
};
