<template>
  <div id="app">
    <VaridigeHeader @toggle-menu-open="toggleMenuOpen" show-company-name :main-menu-open="viewOptions.mainMenuOpen" />
    <div class="container-fluid main-page" :class="{ open: viewOptions.mainMenuOpen }">
      <VaridigeMenu :view-options="viewOptions" @reset-survey="resetSurvey" @open-survey-file="openSurveyFile" @save-survey-file="saveSurveyFile" @send-survey-file="sendSurvey" />
      <div class="main-content-container">
        <router-view class="main-content" />
        <VaridigeFooter />
      </div>
    </div>
    <form class="d-none">
      <input ref="input-file" type="file" id="varige_survey_file" tabindex="-1" @change.prevent="selectFile">
    </form>
    <ConfirmBox />
    <AlertBox />
  </div>
</template>

<script>

import { saveAs } from 'file-saver';
import goToStepMixin from '@/mixins/goToStepMixin.js';
import surveyMixin from '@/mixins/surveyMixin.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [goToStepMixin, surveyMixin],
  components: {
    VaridigeHeader: () => import('@/views/components/Header.vue'),
    VaridigeFooter: () => import('@/views/components/Footer.vue'),
    VaridigeMenu: () => import('@/views/components/Menu.vue'),
    AlertBox: () => import(/* webpackChunkName: "component-alert-modal" */ '@/views/components/Layout/AlertBox.vue'),
    ConfirmBox: () => import(/* webpackChunkName: "component-confirm-modal" */ '@/views/components/Layout/ConfirmBox'),
  },
  data () {
    return {
      viewOptions: {
        mainMenuOpen: false,
        result: {
          calculated: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters('survey', {
      surveyFilename: 'getOutputFilename',
      surveyContent: 'getFileContent',
    }),
  },
  methods: {
    toggleMenuOpen () {
      this.viewOptions.mainMenuOpen = !this.viewOptions.mainMenuOpen;
    },
    resetSurvey () {
      this.$store.commit('survey/resetSurvey');
      this.goToStep(1);
    },
    saveSurveyFile () {
      const blob = new Blob([this.surveyContent], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, this.surveyFilename);
    },
    openSurveyFile () {
      this.$refs['input-file'].click();
    },
    selectFile (e) {
      if (!e.target.files[0]) {
        return;
      }

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = eReadFile => {
        const contentRead = eReadFile.target.result;

        if (!contentRead) {
          return;
        }

        this.$store.commit('survey/loadSurveyFromFileContent', contentRead);
        this.goToStep(1);
        this.$refs['input-file'].value = '';
        this.$refs['input-file'].type = '';
        this.$refs['input-file'].type = 'file';
      };
      reader.readAsText(file);
    },
  },
};

</script>

<style lang="scss">

.term-modal, .law-modal {
  max-width: 60vw !important;

  .modal-content {
    max-height: 90vh !important;

    .modal-body {
      overflow: auto !important;
      padding: 2rem !important;
    }
  }
}

</style>
