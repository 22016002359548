const createUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
  const r = Math.random() * 16 | 0;
  const v = c === 'x' ? r : (r & 0x3 | 0x8);

  return v.toString(16);
});

const calculateHashCode = (data, referenceYear, uuid) => {
  const string = String(referenceYear) + uuid + JSON.stringify(data);

  let i;
  let l;
  let hval = 0x811c9dc5;

  for (i = 0, l = string.length; i < l; i++) {
    hval ^= string.charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }

  return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
};

const calculateReferenceYear = () => {
  const now = new Date();

  const year = Number(now.getFullYear());
  const month = Number(now.getMonth() + 1);

  return (month > 6) ? (year - 1) : (year - 2);
};

const getCurrentDate = () => {
  const currentDate = new Date();

  return currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate() + '-' + currentDate.getHours() + '-' + currentDate.getMinutes() + '-' + currentDate.getSeconds();
};

export const prepareSurvey = (headers = {}, preferences = {}, data = {}) => ({
  headers: {
    ...{
      appVersion: '2.0.0',
      fileVersion: '2.0.0',
      type: 'company',
      createdAt: getCurrentDate(),
      referenceYear: calculateReferenceYear(),
      fileUuid: createUuid(),
    },
    ...(headers || {}),
  },
  preferences: {
    ...{ activeStep: 1 },
    ...(preferences || {}),
  },
  data: {
    ...(data || {}),
  },
});

export const prepareFileContent = (headers, preferences, data) => {
  const fileContent = prepareSurvey(headers || {}, preferences || {}, data || {});
  fileContent.headers.dataHash = calculateHashCode(data || {}, headers?.referenceYear || null, headers?.fileUuid || null);

  return btoa(JSON.stringify(fileContent));
};

export const loadFromFileContent = content => {
  try {
    return JSON.parse(content);
  } catch (e) {}

  try {
    return JSON.parse(atob(content));
  } catch (e) {}

  return {};
};

export default {
  prepareSurvey,
  prepareFileContent,
  loadFromFileContent,
};
