export const universities = [
  { id: 'unich', name: 'Università degli Studi "Gabriele d\'Annunzio"' },
  { id: 'univaq', name: 'Università degli Studi dell\'Aquila' },
  { id: 'unite', name: 'Università degli Studi di Teramo' },
  { id: 'unibas', name: 'Università degli Studi della Basilicata - Potenza, Matera' },
  { id: 'unicz', name: 'Università degli Studi di Catanzaro "Magna Græcia"' },
  { id: 'unical', name: 'Università degli Studi della Calabria' },
  { id: 'unirc', name: 'Università degli Studi di Reggio Calabria "Mediterranea"' },
  { id: 'unina', name: 'Università degli Studi di Napoli "Federico II"' },
  { id: 'unior', name: 'Università degli Studi di Napoli "L\'Orientale"' },
  { id: 'uniparthenope', name: 'Università degli Studi di Napoli "Parthenope"' },
  { id: 'unicampania', name: 'Università degli Studi della Campania Luigi Vanvitelli' },
  { id: 'unisa', name: 'Università degli Studi di Salerno' },
  { id: 'unisannio', name: 'Università degli Studi del Sannio' },
  { id: 'unibo', name: 'Alma mater studiorum - Università di Bologna' },
  { id: 'unife', name: 'Università degli Studi di Ferrara' },
  { id: 'unimore', name: 'Università degli Studi di Modena e Reggio Emilia' },
  { id: 'unipr', name: 'Università degli Studi di Parma' },
  { id: 'units', name: 'Università degli Studi di Trieste' },
  { id: 'uniud', name: 'Università degli Studi di Udine' },
  { id: 'unicas', name: 'Università degli Studi di Cassino' },
  { id: 'uniroma4', name: 'Università degli Studi di Roma "Foro Italico"' },
  { id: 'uniroma1', name: 'Università degli Studi di Roma "La Sapienza"' },
  { id: 'uniroma2', name: 'Università degli Studi di Roma Tor Vergata' },
  { id: 'uniroma3', name: 'Università degli Studi Roma Tre' },
  { id: 'unitus', name: 'Università degli Studi della Tuscia' },
  { id: 'unige', name: 'Università degli Studi di Genova' },
  { id: 'unibg', name: 'Università degli Studi di Bergamo' },
  { id: 'unibs', name: 'Università degli Studi di Brescia' },
  { id: 'polimi', name: 'Politecnico di Milano' },
  { id: 'unimi', name: 'Università degli Studi di Milano' },
  { id: 'unimib', name: 'Università degli Studi di Milano-Bicocca' },
  { id: 'iusspv', name: 'Istituto Universitario di Studi Superiori - Pavia' },
  { id: 'unipv', name: 'Università degli Studi di Pavia' },
  { id: 'uninsubria', name: 'Università degli Studi dell\'Insubria - Varese, Como' },
  { id: 'univpm', name: 'Università Politecnica delle Marche' },
  { id: 'unicam', name: 'Università degli Studi di Camerino' },
  { id: 'unimc', name: 'Università degli Studi di Macerata' },
  { id: 'uniurb', name: 'Università degli Studi di Urbino - Urbino, Fano, Pesaro' },
  { id: 'unimol', name: 'Università degli Studi del Molise' },
  { id: 'uniupo', name: 'Università degli Studi del Piemonte Orientale "Amedeo Avogadro"' },
  { id: 'unito', name: 'Università degli Studi di Torino' },
  { id: 'polito', name: 'Politecnico di Torino' },
  { id: 'poliba', name: 'Politecnico di Bari' },
  { id: 'uniba', name: 'Università degli Studi di Bari' },
  { id: 'unifg', name: 'Università degli Studi di Foggia' },
  { id: 'unisalento', name: 'Università del Salento' },
  { id: 'unica', name: 'Università degli Studi di Cagliari' },
  { id: 'uniss', name: 'Università degli Studi di Sassari' },
  { id: 'unict', name: 'Università degli Studi di Catania' },
  { id: 'unime', name: 'Università degli Studi di Messina' },
  { id: 'unipa', name: 'Università degli Studi di Palermo' },
  { id: 'unifi', name: 'Università degli Studi di Firenze' },
  { id: 'imtlucca', name: 'IMT - Scuola IMT Alti Studi Lucca' },
  { id: 'sns', name: 'Scuola Normale Superiore' },
  { id: 'santannapisa', name: 'Scuola superiore di studi universitari e di perfezionamento Sant\'Anna' },
  { id: 'unipi', name: 'Università di Pisa' },
  { id: 'unisi', name: 'Università degli Studi di Siena' },
  { id: 'unistrasi', name: 'Università per stranieri di Siena' },
  { id: 'unitn', name: 'Università degli Studi di Trento' },
  { id: 'unipg', name: 'Università degli Studi di Perugia' },
  { id: 'unistrapg', name: 'Università per stranieri di Perugia' },
  { id: 'unipd', name: 'Università degli Studi di Padova' },
  { id: 'unive', name: 'Università Ca\' Foscari di Venezia' },
  { id: 'iuav', name: 'Università Iuav di Venezia' },
  { id: 'univr', name: 'Università degli Studi di Verona' },
];

export const getUniversity = code => {
  const university = universities.find(u => u.id === code);
  if (!university) {
    return null;
  }

  return university;
};

export const formatUniversityName = code => {
  const university = getUniversity(code);
  if (university === null) {
    return '';
  }

  return university.name;
};
