export const cunAreas = [
  { id: 0, name: 'Scienze matematiche e informatiche' },
  { id: 1, name: 'Scienze fisiche' },
  { id: 2, name: 'Scienze chimiche' },
  { id: 3, name: 'Scienze della Terra' },
  { id: 4, name: 'Scienze biologiche' },
  { id: 5, name: 'Scienze mediche' },
  { id: 6, name: 'Scienze agrarie e veterinarie' },
  { id: 7, name: 'Ingegneria civile e architettura' },
  { id: 8, name: 'Ingegneria industriale e dell\'informazione' },
  { id: 9, name: 'Scienze dell\'antichità, filologico-letterarie e storico-artistiche' },
  { id: 10, name: 'Scienze storiche, filosofiche, pedagogiche e psicologiche' },
  { id: 11, name: 'Scienze giuridiche' },
  { id: 12, name: 'Scienze economiche e statistiche' },
  { id: 13, name: 'Scienze politiche e sociali' },
];

export const getCunArea = code => {
  const cunArea = cunAreas.find(sf => sf.id === code);
  if (!cunArea) {
    return null;
  }

  return cunArea;
};

export const formatCunArea = code => {
  const cunArea = getCunArea(code);
  if (cunArea === null) {
    return '';
  }

  return cunArea.name;
};
