import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';
import laws from '@/data/laws.json';

export default {
  mixins: [modalsManagerMixin],
  data () {
    return {
      law: null,
      lawText: null,
    };
  },
  methods: {
    showLaw (lawId) {
      const law = (laws?.data || []).find(l => l.id === lawId);
      if (!law) {
        return;
      }

      this.law = law.name;
      this.lawTitle = law.title;
      this.lawText = law.html;
      this.$openModal('laws');
    },
    closeLaw () {
      this.law = null;
      this.lawTitle = null;
      this.lawText = null;
      this.$closeModal('laws');
    },
  },
};
